@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* styles.css */
@font-face {
  font-family: "Poppins";
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Helvetica', 'Helvetica Neue', 'Arial',
    sans-serif;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  color: #333333;
}

a {
  text-decoration: none !important;
}

.font-montserrat {
  font-family: "Poppins", sans-serif !important;
}

.font-open-sans {
  font-family: "Poppins", sans-serif;
}

.font-georgia {
  font-family: 'Poppins' !important;
}

.font-arial {
  font-family: 'Poppins' !important;
}

.font-open-sans {
  font-family: 'Poppins', sans-serif;
}

label {
  display: block;
  font-size: 18px;
  line-height: 20px;
  font-family: "Poppins";
  font-weight: 600;

}

textarea,
select {
  background-color: #fff;
  border-radius: 3px;
}

input,
textarea,
select {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  transition: border linear .2s, box-shadow linear .2s;
  outline: none;
  padding: 9px 10px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid #8B919E;
  font-family: "Poppins", sans-serif;
  color: #555555;
}

input:focus,
textarea:focus {
  border-color: rgba(69, 69, 69, 0.8);
  border-width: 1px;
  outline: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(69, 69, 69, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(69, 69, 69, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(69, 69, 69, 0.6);
}

.btn-primary {
  appearance: button;
  -webkit-appearance: button;
  background-color: #113355;
  border-width: 1px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

.btn-primary:hover {
  background: #113355;
}

.form-tabs-button {
  padding-right: 25px;
  border-right-width: 3px;
  border-color: transparent;
}

.form-tabs-button button.active {
  border-color: #8F8F8F;
  border: 1px solid #8F8F8F;
}

.form-tabs-button::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 71%;
  background-color: #ffc004;
  right: 0;
  margin: auto;
  top: 40%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.form-tabs-button button::before {
  position: absolute;
  content: '';
  width: 25px;
  height: 1px;
  background: #ffc004;
  right: -26px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.form-tabs-button button::after {
  position: absolute;
  content: '';
  width: 30px;
  border-radius: 50%;
  height: 30px;
  background: #ffc004;
  right: -40px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 6px solid #ffc004;
  z-index: 1;
}

.form-tabs-button button.active::after {
  background: #fff;
}

.registration-from input,
.registration-from select {
  width: 220px;
}

.registration-from select {
  height: 41px;
}

.aftercare-details-form input {
  margin-top: 10px;
}

.electronic-details-form input {
  width: 90px;
}


.window-film-details-form input {
  width: 270px
}

.window-film-details-form select {
  width: 270px;
  margin: 8px 0;
}

.form-tabs-button.inner-tabs button::before,
.form-tabs-button.inner-tabs button::after {
  display: none;
}

.window-film-details-form tr td:first-child {
  width: 300px;
}

.form-tabs-button.inner-tabs {
  padding-right: 0;
  border-right-width: 0;
  border-color: #8B919E;
}

.form-tabs-button.inner-tabs button {
  margin-bottom: -1px;
  font-weight: 600;
}

.form-tabs-button.inner-tabs button.active {
  border-right-width: 3px;
}

.line-chart-container {
  width: 100%;
}

.line-chart-canvas {
  width: 100% !important;
}

@media (max-width:1360px) {
  .customer-details-table tr {
    padding-right: 0;
  }
}

@media (max-width:960px) {
  .customer-details-table tr {
    padding-right: 0;
  }
}

/* input[type="checkbox"] {
  width: auto !important;
} */

.header-popper {
  z-index: 99999 !important;
}

table tbody tr:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.user-profile {
  margin: 0 auto;
  padding: 20px;
}

.profile-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border: 2px solid #ccc;

}

.profile-table td {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  /* font-weight: bold; */
}

.profile-table td:first-child {
  font-weight: bold;
  width: 150px;
}

.profile-table input[type="text"],
.profile-table input[type="email"],
.profile-table input[type="number"]{
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.profile-table textarea {
  height: 80px;
}

.save-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffc004;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #ffc004;
}

.error-message{
  color: red;
}
@media print {
  body * {
      visibility: hidden;
  }

  #printArea, #printArea * {
      visibility: visible;
  }

  #printArea {
      position: absolute;
      left: 0;
      top: 0;
  }

  button {
      display: none;
  }
}

.print-area {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 800px;
  margin: auto;
}

.print-h1, .print-h2 {
  color: #333;
  text-align: center;
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.item {
  flex: 1 1 45%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item p {
  margin: 5px 0;
}

.electronic-item {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
  flex-basis: 100%;
}

strong {
  width: 40%;
}

.value {
  width: 60%;
  text-align: left;
}



.error-message-size{
  font-size: 14px;
}


.popup-delete {
  background-color: red;
  border-radius: 12px;
  font-size: 18px;
}
.popup-password {
  background-color: #0a0a50;
  border-radius: 12px;
  font-size: 18px;
}
 
.popup-card{
  border-radius: 15px;
}

.icon-color{
  color: rgb(152, 30, 30);
}


.error-message-width{
  width: 48%;
}



.check-box-width-setting{
  width: 16px !important;
  margin-left: 19px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.other-icons{
  font-size: 20px;
}


.btn-zoom {
  transition: transform 0.2s ease, color 0.2s ease;
  /* Smooth transition for transform */
  color: unset !important;
}
.btn-zoom:hover {
  transform: scale(1.5);
  /* Scale up by 10% on hover */
  color:rgb(255 192 4 / var(--tw-bg-opacity)) !important;
}
.btn-zoom:not(:hover) {
  transform: scale(1);
  /* Reset to original size when not hovered */
  color: unset !important;
}

.btn-zoom-lock {
  transition: transform 0.2s ease, color 0.2s ease;
  /* Smooth transition for transform */
  color: unset !important;
}
.btn-zoom-lock:hover {
  background-image: url("../../web/src/assets/img/lock-hover.png");
  transform: scale(1.5);
  /* Scale up by 10% on hover */
}
.btn-zoom-lock:not(:hover) {
  transform: scale(1);
  /* Reset to original size when not hovered */
  color: unset !important;
}